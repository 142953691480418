import React from "react";
import HTML from "../assets/images/tecnologias/html.png";
import CSS from "../assets/images/tecnologias/css-3.png";
import JS from "../assets/images/tecnologias/js.png";
import ReactJS from "../assets/images/tecnologias/react.png";
import NodeJS from "../assets/images/tecnologias/node-js.png";
import MongoDB from "../assets/images/tecnologias/mongo.png";
import Firebase from "../assets/images/tecnologias/firebase.png";
import MySQL from "../assets/images/tecnologias/mysql-logo.png";
import Tailwind from "../assets/images/tecnologias/tailwindcss.png";
import Git from "../assets/images/tecnologias/git.png";
import Sass from "../assets/images/tecnologias/sass.png";
import Express from "../assets/images/tecnologias/express.png";
import Socketio from "../assets/images/tecnologias/socketio.png";
import Python from "../assets/images/tecnologias/python.png";
import Django from "../assets/images/tecnologias/django.png";
import Redis from "../assets/images/tecnologias/redis.png";

const Technologies = () => {

  const technologies = [
    { img: JS, name: "JavaScript", left: "10vw", top: "15vh", size: "100px" },
    { img: ReactJS, name: "React", left: "12vw", top: "30vh", size: "100px" },
    { img: NodeJS, name: "Node.js", left: "16vw", top: "18vh", size: "100px" },
    { img: Express, name: "Express.js", left: "17vw", top: "7vh", size: "80px" },
    { img: HTML, name: "HTML", left: "28vw", top: "23vh", size: "60px" },
    { img: CSS, name: "CSS", left: "18vw", top: "32vh", size: "70px" },
    { img: Sass, name: "Sass", left: "13vw", top: "7vh", size: "60px" },
    { img: Tailwind, name: "Tailwind CSS", left: "5vw", top: "18vh", size: "70px" },
    { img: Git, name: "Git", left: "28vw", top: "31vh", size: "70px" },
    { img: Socketio, name: "Socket.io", left: "10vw", top: "8vh", size: "50px" },
    { img: MySQL, name: "MySQL", left: "7vw", top: "27vh", size: "80px" },
    { img: MongoDB, name: "MongoDB", left: "28vw", top: "13vh", size: "80px" },
    { img: Redis, name: "Redis", left: "6vw", top: "10vh", size: "60px" },
    { img: Firebase, name: "Firebase", left: "23vw", top: "33vh", size: "70px" },
    { img: Python, name: "Python", left: "22vw", top: "9vh", size: "100px" },
    { img: Django, name: "Django", left: "22vw", top: "21vh", size: "100px" },
  ];

  return (
    <div className="floating grid md:grid-cols-8 gap-2 sm:grid-cols-6 grid-cols-4 xl:hidden">
      {technologies.map((tech) => (
        <div className=" flex flex-col w-full xl:absolute items-center" style={{ top: tech.top, left: tech.left }}>
          <img
            src={tech.img}
            key={tech}
            alt=""
            className="border-2 border-black rounded-md p-3 bg-zinc-800 h-20 w-20"
          />
        </div>
      ))}
    </div>
  );
}

export default Technologies;
