import React from 'react'
import { Link } from 'react-scroll'

const Navbar = () => {

  return (
    <div name="navbar" className="animate__animated animate__fadeInLeft h-screen z-30 fixed lg:block hidden left-0 w-16 pt-[20vh] bg-[#0000005e]">
      <ul className='flex flex-col h-full items-center justify-around text-white transition-all'>
        <li className='transform -rotate-90'>
          <Link activeClass="border-b-2 border-cyan-400" to="about" spy={true} smooth={true} duration={500}> About </Link>
        </li>
        <li className='transform -rotate-90'>
          <Link activeClass="border-b-2 border-cyan-400" to="education" spy={true} smooth={true} duration={500}> Education </Link>
        </li>
        <li className='transform -rotate-90'>
          <Link activeClass="border-b-2 border-cyan-400" to="certifications" spy={true} smooth={true} duration={500}> Certifications </Link>
        </li>
        <li className='transform -rotate-90'>
          <Link activeClass="border-b-2 border-cyan-400" to="contact" spy={true} smooth={true} duration={500}> Contact </Link>
        </li>
      </ul>
    </div>
  )
}

export default Navbar
