import About from './components/About.jsx';
import Contact from './components/Contact.jsx';
import Technologies from './components/Technologies.jsx';
import Navbar from './components/Navbar.jsx';
import Certifications from './components/Certifications.jsx';
import Footer from './components/Footer.jsx';
import AOS from "aos";
import 'animate.css';
import "aos/dist/aos.css";

function App() {

  AOS.init();
  
  return (
    <div className="App max-h-fit scrollbar-thin scrollbar-thumb-emerald-400 scrollbar-track-slate-700 bg-[#12181B]">
      <Navbar />
      <About />
      <Technologies />
      <Certifications />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
