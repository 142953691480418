import React from 'react'
import TechnologiesCloud from './TechnologiesCloud';
import TechnologiesGrid from './TechnologiesGrid';

const Technologies = () => {

  return (
    <div name="education" className="text-white spacer spacer2 flex xl:flex-row xl:gap-0 gap-10 justify-center xl:items-start pt-[25vh] items-center flex-col w-full h-screen xl:justify-around">
      <div className='flex flex-col items-center justify-center'>
        <p className='text-3xl font-bold w-fit'>Here are some of the technologies that i use</p>
        <TechnologiesCloud />
        <TechnologiesGrid />
      </div>
      <div className='xl:w-[35vw] lg:w-[60vw] w-[80vw] md:text-xl text-sm text-justify h-fit flex flex-col lg:gap-14 gap-8 bg-[#12181B] md:p-8 p-4 rounded-md drop-shadow-[-5px_5px_20px_rgba(0,0,0,1)]'>
        <h2 className='md:text-3xl text-xl text-[#12181B] px-4 py-2 bg-blue-400 self-end drop-shadow-[0_0_15px_rgba(96,165,250,0.3)]'>Where did I learn them?</h2>
        <p>I made a lot of courses, initially in Coderhouse.com, where I started with the basics like <span className="border-b border-[#ff9665] font-bold">HTML</span>, <span className="border-b border-blue-400 font-bold">CSS</span>, <span className="border-b border-yellow-300 font-bold">JS</span> and <span className="border-b border-cyan-300 font-bold">React</span>. <br /> Later I continued with backend, using <span className="border-b border-green-600 font-bold">Node.js</span> with <span className="border-b border-slate-400 font-bold">express</span>, learning about SQL and NoSQL databases. <br /> I also made courses on LinkedIn Learning and a few more pages.</p>
        <p>I also used JWT, Passport, Objection.js with Knex.js, template engines like Ejs, Pug, Handlebars and a little of mocha & chai for testing.</p>
        <p>Currently I'm studying Computer Engineering in the National University of La Matanza, Buenos Aires.</p>
      </div>
    </div>
  )
}

export default Technologies