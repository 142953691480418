import React from 'react'
import Lottie from 'lottie-react';
import certificate from '../assets/lotties/certificate.json'

import certCarrera from '../assets/images/certificados/certificado carrera.png'
import certBackend from '../assets/images/certificados/certificado backend.png'
import certDesarrollo from '../assets/images/certificados/certificado desarrollo.png'
import certJavascript from '../assets/images/certificados/certificado js.png'
import certReact from '../assets/images/certificados/certificado react.png'
import certPython from '../assets/images/certificados/certificado python.png'
import certDesarrolloTelef from '../assets/images/certificados/certificado desarrollo telefonica.png'
import certJavascriptTelef from '../assets/images/certificados/certificado js telefonica.png'
import introAWSLI from '../assets/images/certificados/introduccion AWS LinkedIn.png'
import JSbuenasPract from '../assets/images/certificados/JS buenas practicas LinkedIn.png'
import JSerrores from '../assets/images/certificados/JS errores LinkedIn.png'
import JSesencial from '../assets/images/certificados/JS esencial LinkedIn.png'
import JSespecialista from '../assets/images/certificados/JS especialista LinkedIn.jpg'
import JSOOP from '../assets/images/certificados/JS OOP LinkedIn.png'
import JSregExp from '../assets/images/certificados/JS regExp LinkedIn.png'
import JSTDD from '../assets/images/certificados/JS TDD LinkedIn.png'
import JStrucos from '../assets/images/certificados/JS trucos LinkedIn.png'
import PYesencial from '../assets/images/certificados/python esencial LinkedIn.png'
import Reactavan1 from '../assets/images/certificados/React avanzado 1 LinkedIn.png'
import ReactEsencial from '../assets/images/certificados/React esencial LinkedIn.png'
import TSesencial from '../assets/images/certificados/typescript esencial LinkedIn.png'

const ArrayCert = [certCarrera, certBackend, certDesarrollo, certJavascript, certReact, certPython, JSespecialista, JSesencial, JSbuenasPract, JSerrores, JSOOP, JStrucos, JSTDD, JSregExp, ReactEsencial, Reactavan1, TSesencial, PYesencial, introAWSLI, certDesarrolloTelef, certJavascriptTelef]

const Certifications = () => {

  return (
    <div name="certifications" className="text-xs md:text-xl spacer spacer4 flex flex-col items-center justify-center w-full min-h-screen text-white">
      <div className="mb-10 mt-10 text-center">
        <h2 className="text-3xl px-4 py-2 bg-[#fcf370] text-[#12181B] drop-shadow-[0_0_15px_rgba(252,243,112,0.3)]">Look at my <br /> certifications!</h2>
      </div>
      <div data-aos="zoom-in" className="relative rounded-lg overflow-auto md:w-[50vw] w-[80vw]">
        <div className="scrollbar scrollbar-thumb-emerald-400 scrollbar-track-slate-700 relative w-full flex gap-6 snap-x snap-proximity overflow-x-auto pb-14">
          {ArrayCert.map((cert, index) => (
            <div key={index} className="snap-center shrink-0">
              <img className="shrink-0 md:w-[30vw] w-[70vw] rounded-lg" src={cert} alt=""></img>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Certifications