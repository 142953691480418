import React from 'react'
import reactBadge from '../assets/badge-react.svg'
import tailwindBadge from '../assets/badge-tailwindcss.svg'

const Footer = () => {

  return (
    <div name="footer" className="text-white text-xs w-full h-[30px] bg-[#0000005e] flex items-center justify-around">
      <p> Matias Nahuel Pizzi © 2023</p>
      <p>Made with ❤️ and ☕</p>
      <div className="md:flex hidden gap-4">
        <img src={reactBadge} alt="react badge" className="h-[22px]"/>
        <img src={tailwindBadge} alt="tailwind badge" className="h-[22px]"/>
      </div>
    </div>
  )
}

export default Footer