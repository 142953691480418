import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
  const [success, setSuccess] = useState(false);
  const [verified, setVerified] = useState(false);

  const captcha = useRef(null);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    if (verified) {
      emailjs.sendForm(
        // This are my credentials, you can use your own
        // please, don't use mine :(
        // https://www.emailjs.com/ 
        // (free plan is enough)
        "service_23qxnr7",
        "template_r47lhcc",
        form.current,
        "vAB9htGbi01U-J4B4"
      ).then(setSuccess(true));
    } else {
      document.getElementById("alert").style.display = "block";
    }
  };

  const handleVerify = () => {
    if (captcha.current.getValue()) {
      setVerified(true);
      document.getElementById("alert").style.display = "none";
    }
  };

  const handleChange = (e) => {
    if (verified) captcha.current.reset();
    setVerified(false);
    setSuccess(false);
  };

  return (
    <div className="drop-shadow-[0_15px_30px_rgba(0,0,0,1)] flex flex-col w-[80%] md:w-[80%] rounded-lg mt-6 pb-4 bg-[#12181B]">
      <form
        id="form"
        ref={form}
        onSubmit={sendEmail}
        action="https://formsubmit.co/matias.nahuel.pizzi@hotmail.com"
        method="POST"
      >
        <div className="flex flex-col w-full md:py-8 py-4 md:px-8 px-6 text-white">
          <div className="w-full flex gap-8 flex-col items-center">
            <p className="px-2">
              If you have any questions, please fill out the form below and I
              will get back to you as soon as possible.
            </p>
            <div className="w-[90%] lg:max-w-[65%] flex flex-col gap-4">
              <input name="from_name" type="text" required className="pl-3 h-[30px] rounded bg-slate-700" placeholder="Name" onChange={handleChange} />
              <input name="from_email" type="email" required className="pl-3 h-[30px] rounded bg-slate-700" placeholder="Email" onChange={handleChange} />
              <textarea name="message" required className="max-w-[700px] pl-3 pt-1 md:h-52 h-32 rounded bg-slate-700" placeholder="Message" onChange={handleChange} />
            </div>
            <div>
              <ReCAPTCHA ref={captcha} theme={"dark"} sitekey="6Lc30g4kAAAAAE3bAukIcsJXxMBi-PG-6je_zLBE" onChange={handleVerify} />
              <p className="text-red-400 text-xs text-center w-full hidden" id="alert">Please resolve the captcha</p>
            </div>
            <div className="w-full flex flex-col items-center">
              <button className={success ? "hidden" : "hover:bg-slate-600 bg-slate-700 px-4 py-1 rounded"} type="submit">
                <span>Submit</span>
              </button>
            </div>
            {success && (
              <div class="bg-green-100 rounded-lg py-3 px-6 font-bold text-green-700" role="alert">
                <p>Message sent successfully!</p>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
