import React from 'react'

const DownloadResume = () => {
  
  return (
    <div className='rounded-lg border-2 border-slate-500 p-6 border-dashed'>
      <div className='flex sm:gap-4 gap-2 flex-row w-full font-bold text-[#12181B] items-center'>
        <button className="hover:bg-[#a9ffb6] hover:border-[#61996a] bg-[#70fc84] py-2 px-4 rounded border-b-[3px] border-[#0ca323]" onClick={() => { window.open('cv_Matias_pizzi(e).pdf'); }}>English</button>
        <p className="text-white">or</p>
        <button className="hover:bg-[#a9ffb6] hover:border-[#61996a] bg-[#70fc84] py-2 px-4 rounded border-b-[3px] border-[#0ca323]" onClick={() => { window.open('cv_Matias_pizzi.pdf'); }}>Spanish</button>
      </div>
    </div>
  )
}

export default DownloadResume