import React from 'react'
import ContactForm from './ContactForm'
import DownloadResume from './DownloadResume'
import ContactLinks from './ContactLinks'

const Contact = () => {

  return (
    <div name="contact" className="spacer spacer3 flex flex-col items-center min-h-screen h-[1000px] 2xl:h-fit w-full text-white mt-20">
      <h1 className='text-3xl mb-10 px-4 py-2 bg-[#70fc84] text-[#12181B] drop-shadow-[0_0_15px_rgba(80,236,148,0.3)]'>Contact</h1>
      <div className='flex flex-col justify-center items-center max-w-[1000px] gap-6'>
        <div className='floating flex flex-col 2xl:absolute 2xl:right-[10vw] items-center pb-4'>
          <p className='text-lg'>You can see my <span className='font-bold '>resume</span>  here:</p>
          <DownloadResume />
        </div>
        <ContactLinks />
        <div className='h-full w-full flex flex-col justify-center items-center'>
          <ContactForm />
        </div>
      </div>
    </div>
  )
}

export default Contact