import React from 'react'
import Perfil from '../assets/images/perfil.jpg'
import Lottie from 'lottie-react';
import mouseScroll from '../assets/lotties/mouse-scroll.json'
import screen from '../assets/lotties/screen.json'
import { useState, useEffect } from 'react';

const About = () => {

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, [])

  const listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > 800) {
      isVisible && setIsVisible(false)
    } else {
      setIsVisible(true);
    }
  };

  return (
    <div name="about" className="md:text-xl spacer spacer1 flex flex-col items-center justify-center w-full min-h-screen text-white">
      <div className='w-full flex flex-col z-10 justify-center gap-10 items-center md:flex-row md:pt-0'>
        <div className='animate__animated animate__fadeInLeft md:text-2xl text-lg md:max-w-[60%] gap-[20px] flex flex-col' >
          <p className='md:text-3xl text-xl'>Hi, my name is</p>
          <h1 id="name" className="md:text-6xl text-3xl text-[#67ffc0] typing drop-shadow-[0_0_10px_rgba(103,255,192,0.3)]"> Matias Pizzi </h1>
          <p className='gap-2'> I'm a <span className='text-[#7edbfff5] font-bold'>Full Stack </span> junior developer <br /> based in 📍Buenos Aires, Argentina 🇦🇷 </p>
        </div>
        <img src={Perfil} alt="" className='animate__animated animate__fadeInRight md:w-80 w-60 z-20 rounded-full drop-shadow-[-5px_5px_30px_rgba(0,0,0,1)]' />
      </div>
      {window.innerWidth > 768 && isVisible ? <Lottie animationData={mouseScroll} loop={true} className="hidden lg:block absolute bottom-[4vh]" /> : null}
    </div>
  )
}

export default About